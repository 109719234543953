import { Auth } from "aws-amplify"
import React, { Component } from "react"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./SavedSupplierNoteModal.module.scss"
import { eventGtag } from "@lib/gtag"
import lambdas from "@data/lambdas"
import ReactModal from "@components/global/ReactModal/ReactModal"
import Button from "@components/objects/Button/Button"

const { PARTY_SUPPLIER_SUBMIT } = lambdas[process.env.NEXT_PUBLIC_ENV]

export default class SavedSupplierNoteModal extends Component {
  state = {
    modalIsOpen: false,
    note: this.props.supplierNote,
  }

  handleChange = (event) => {
    let target = event.target
    this.setState({
      note: target.value,
    })
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  saveNote = async (event) => {
    event.preventDefault()

    try {
      const data = {
        createdAt: this.props.party.partyId,
        supplierId: this.props.supplierId,
        note: this.state.note,
        isUpdatingNote: true,
      }

      const user = await Auth.currentSession()

      const response = await fetch(PARTY_SUPPLIER_SUBMIT, {
        method: "POST",
        body: JSON.stringify({
          sessionToken: user.getIdToken().getJwtToken(),
          data: data,
        }),
      })

      if (response.ok) {
        //analytics
        eventGtag({
          action: "Update",
          params: {
            event_category: "Supplier Note",
            event_label: this.props.supplierName,
            value: this.props.supplierId,
          },
        })
        this.props.onClose(this.state.note)
        this.closeModal()
      } else {
        alert(
          "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
        )
      }
    } catch (err) {
      console.log(err)
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
  }

  render() {
    return (
      <ReactModal
        button={this.props.button}
        modalLabel="Add Note"
        modalIsOpen={this.state.modalIsOpen}
        openModal={this.openModal}
        onRequestClose={this.closeModal}
      >
        <section className={styles.EditPartyModal}>
          <SiteWrapper className={styles}>
            <form>
              <div className={styles.full}>
                <div className={styles.center}>
                  <h2>{this.props.supplierName}</h2>
                  <p>Write a note</p>
                </div>
                <textarea placeholder="Type here..." onChange={this.handleChange} value={this.state.note}></textarea>
              </div>
              <div className={styles.buttons}>
                <Button text="Save" color="pink" type="button" onClick={this.saveNote} />
              </div>
            </form>
          </SiteWrapper>
        </section>
      </ReactModal>
    )
  }
}
