import { Auth } from "aws-amplify"
import Cookies from "js-cookie"
import moment from "moment"
import React, { Component } from "react"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import { connect } from "react-redux"
import ReactSelect from "react-select"
import { withRouter } from "next/router"
import SiteWrapper from "../../structure/SiteWrapper/SiteWrapper"
import * as styles from "./EnquireForm.module.scss"
import { eventGtag } from "@lib/gtag"
import trackFBEvent from "@lib/trackFBEvent"
import trackUser from "@lib/trackUser"
import fetchLambda from "@data/fetchLambda"
import lambdas from "@data/lambdas"
import { minimumSelectStyle } from "@data/static"
import { budgetOptions } from "@data/static"
import * as actions from "@store/actions"
import Button from "@components/objects/Button/Button"

const { ENQUIRY_SUBMIT, MAILCHIMP_REQUEST, STRIPE_ACTIVATE } = lambdas[process.env.NEXT_PUBLIC_ENV]

class EnquireForm extends Component {
  state = {
    data: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      partyGuestsNumber: "",
      supplierBudget: "",
      enquireFormNotes:
        "Hi there! \n\nI would like to enquire about your services for my event, please can you get in touch with me and we can have a chat? \n\nThanks",
      newsletterConfirm: true,
    },
    isProcessing: false,
  }

  handleSubmit = async (e) => {
    e.preventDefault()

    this.setState({
      isProcessing: true,
    })

    const isEnquireAll = !!(this.props.suppliers && this.props.suppliers.length)

    //only set session token if logged in.
    let sessionToken
    try {
      const user = await Auth.currentSession()
      sessionToken = user.getIdToken().getJwtToken()
    } catch (err) {
      if (err === "No current user") {
        sessionToken = false
      }
    }

    try {
      let data = {
        userEmail: this.state.data.email,
        userPhone: this.state.data.phone,
        userFirstName: this.state.data.firstName,
        userLastName: this.state.data.lastName,
        newsletterConfirm: this.state.data.newsletterConfirm,
        partyId: this.state.data.partyId,
        partyCategory: this.state.data.partyCategory,
        partyCategoryLabel: this.state.data.partyCategoryData.label,
        partyLocation: this.getCounty(this.state.data.partyLocation)
          ? this.getCounty(this.state.data.partyLocation)
          : this.state.data.partyLocation,
        partyLocationLabel: this.state.data.partyLocationData.label,
        partyGuestsNumber: this.state.data.partyGuestsNumber,
        supplierBudget: this.state.data.supplierBudget,
        partyName: this.state.data.partyName !== "-------" ? this.state.data.partyName : "",
        partyDate:
          this.state.data.partyDate && this.state.data.partyDate.format("Do MMM YYYY")
            ? this.state.data.partyDate.format("Do MMM YYYY")
            : this.state.data.partyDate,
        formNotes: this.state.data.enquireFormNotes,
        isEnquireAll: isEnquireAll,
      }

      if (isEnquireAll) {
        let suppliers = []
        let suppliersEmails = []
        for (const supplier of this.props.suppliers) {
          suppliersEmails.push(supplier.email)
          suppliers.push(supplier)
        }
        data = { ...data, suppliers, suppliersEmails }
      } else {
        const { addressstreet1, addressstreet2, addresspostcode, addresscity } = this.props.supplierContactInfo
        const supplierAddress = `${addressstreet1}${
          addressstreet2 ? ", " + addressstreet2 : ""
        }, ${addresscity} ${addresspostcode}`

        let supplierOffer = null
        if (this.props.supplierContactInfo.offerother || this.props.supplierContactInfo.offer) {
          supplierOffer = this.props.supplierContactInfo.offerother
            ? this.props.supplierContactInfo.offerother
            : `save ${this.props.supplierContactInfo.offer}% with HOPP`
        }

        data = {
          ...data,
          supplierId: this.props.supplierId,
          supplierName: this.props.supplierName,
          supplierService: this.props.supplierService,
          supplierServiceLabel: this.props.supplierServiceLabel,
          supplierEmail: this.props.supplierContactInfo.email,
          supplierPhone: this.props.supplierContactInfo.phone,
          supplierWebsite: this.props.supplierContactInfo.website,
          supplierOffer: supplierOffer,
          supplierAddress: supplierAddress,
        }
      }

      if (this.state.data.newsletterConfirm) {
        const responseMailchimp = await fetch(MAILCHIMP_REQUEST, {
          method: "POST",
          body: JSON.stringify({
            data: {
              email: this.state.data.email,
              mergeFields: {
                FNAME: this.state.data.firstName,
                LNAME: this.state.data.lastName,
              },
              tags: "previous_enquiry",
            },
          }),
        })
        Cookies.set("hopp_newsletter", "subscribed", { expires: 365 })
      }

      const responseEnquiry = await fetch(ENQUIRY_SUBMIT, {
        method: "POST",
        body: JSON.stringify({
          sessionToken: sessionToken,
          data: data,
        }),
      })

      if (responseEnquiry.ok) {
        // change redux supplier status
        const responseData = await responseEnquiry.json()

        if (data.partyId && responseData.inParty && !isEnquireAll && this.props.updateStatus) {
          this.props.updateStatus(1)
        }

        //analytics
        if (isEnquireAll) {
          eventGtag({
            action: "Sent",
            params: {
              event_category: "Enquire All",
            },
          })
          for (const supplier of this.props.suppliers) {
            eventGtag({
              action: "Sent",
              params: {
                event_category: "Enquiry",
                event_label: supplier.name,
                value: supplier.id,
              },
            })
          }
        } else {
          eventGtag({
            action: "Sent",
            params: {
              event_category: "Enquiry",
              event_label: this.props.supplierName,
              value: this.props.supplierId,
            },
          })
        }

        trackFBEvent("Lead", {
          user_email: this.state.data.email,
        })

        trackUser("countEnquiry")

        //check if subscription needs activating
        if (isEnquireAll) {
          if (data.suppliers) {
            for (const supplier of data.suppliers) {
              if (supplier.stripeStatus === "noEnquiry") {
                await fetchLambda(STRIPE_ACTIVATE, {
                  stripeId: supplier.stripeId,
                  cognitoId: supplier.createdby,
                  supplierId: supplier.id ? supplier.id : supplier.id,
                })
              }
            }
          }
        } else {
          if (this.props.supplierStripeStatus === "noEnquiry") {
            await fetchLambda(STRIPE_ACTIVATE, {
              stripeId: this.props.supplierStripeId,
              cognitoId: this.props.supplierCreatedby,
              supplierId: this.props.supplierId,
            })
          }
        }

        const uqid = new Date().valueOf()

        this.props.router.push(
          isEnquireAll
            ? `/thank-you/?suppliers=${JSON.stringify(data.suppliers.map((el) => el.slug))}&e=${
                this.state.data.email
              }&id=${uqid}`
            : `/thank-you/?n=${this.props.supplierName}&e=${this.state.data.email}&id=${uqid}`
        )
      } else {
        alert(
          "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
        )
      }
    } catch (err) {
      console.log(err)
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
  }

  getCounty(item) {
    try {
      const data = JSON.parse(item)
      return data.countyId
    } catch (e) {
      return false
    }
  }

  componentDidMount = () => {
    this.setState({
      data: {
        ...this.state.data,
        ...this.props.currentParty,
        partyDate:
          this.props.currentParty && this.props.currentParty.partyDate && moment(this.props.currentParty.partyDate)
            ? moment(this.props.currentParty.partyDate)
            : this.props.currentParty?.partyDate,
        firstName: this.props.userAttributes.given_name,
        lastName: this.props.userAttributes.family_name,
        email: this.props.userAttributes.email,
      },
    })
  }

  handleChange = (event) => {
    let target = event.target
    let value
    let name

    value = target.type === "checkbox" ? target.checked : target.value
    name = target.name

    const newParty = { ...this.state.data }

    newParty[name] = value

    this.setState({
      data: newParty,
    })
  }

  handleChangeSelect = (selected, type) => {
    const newData = { ...this.state.data }
    newData[type.name] = selected.value
    newData[type.name + "Data"] = selected
    this.setState({
      data: newData,
    })
  }

  handleChangePhone = (value) => {
    this.setState({
      data: { ...this.state.data, phone: value },
    })
  }

  render() {
    const { categoryOptions, countyOptions } = this.props.staticData
    const isEnquireAll = !!(this.props.suppliers && this.props.suppliers.length)

    return (
      <section className={styles.EnquireForm}>
        <SiteWrapper full>
          <form onSubmit={this.handleSubmit}>
            <div className={styles.full}>
              <h2>{isEnquireAll ? "Enquire All Form" : "Enquiry Form"}</h2>
              <div className={styles.grid}>
                <div>
                  <input
                    required
                    type="text"
                    name="firstName"
                    placeholder="First Name*"
                    value={this.state.data.firstName}
                    onChange={this.handleChange}
                  />
                </div>
                <div>
                  <input
                    required
                    type="text"
                    name="lastName"
                    placeholder="Last Name*"
                    value={this.state.data.lastName}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className={styles.fullwidth}>
                <input
                  required
                  type="text"
                  name="email"
                  placeholder="Email*"
                  value={this.state.data.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.grid}>
                <div>
                  <PhoneInput
                    required
                    placeholder="Phone*"
                    defaultCountry="GB"
                    value={this.state.data.phone}
                    onChange={this.handleChangePhone}
                  />
                </div>
                <div className={styles.reactSelect}>
                  <ReactSelect
                    placeholder="Type of party*"
                    styles={minimumSelectStyle}
                    className={styles.reactSelect}
                    id="partyCategory"
                    name="partyCategory"
                    required={true}
                    isMulti={false}
                    value={categoryOptions.filter(({ value }) => value === this.state.data.partyCategory)}
                    onChange={this.handleChangeSelect}
                    options={categoryOptions}
                  />
                  <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, padding: 0 }}
                    value={categoryOptions.filter(({ value }) => value === this.state.data.partyCategory)}
                    required={true}
                  />
                </div>
                <div className={styles.reactSelect}>
                  <ReactSelect
                    placeholder="Location*"
                    styles={minimumSelectStyle}
                    className={styles.reactSelect}
                    id="partyLocation"
                    name="partyLocation"
                    isMulti={false}
                    required={true}
                    value={
                      this.state.data.partyLocationData &&
                      countyOptions.filter(({ value }) => value === this.state.data.partyLocationData.value)
                    }
                    onChange={this.handleChangeSelect}
                    options={countyOptions}
                  />
                  <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0, padding: 0 }}
                    value={
                      this.state.data.partyLocationData &&
                      countyOptions.filter(({ value }) => value === this.state.data.partyLocationData.value)
                    }
                    required={true}
                  />
                </div>
                <div>
                  <SingleDatePicker
                    date={
                      this.state.data.partyDate !== "0" && Number(this.state.data.partyDate)
                        ? this.state.data.partyDate
                        : null
                    }
                    onDateChange={(date) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          partyDate: date ? moment(date) : "0",
                        },
                      })
                    }}
                    focused={this.state.dateFocused}
                    onFocusChange={({ focused }) => {
                      document.querySelector("input#partyDate").readOnly = focused
                      this.setState({ dateFocused: focused })
                    }}
                    placeholder="Date*"
                    id="partyDate"
                    numberOfMonths={1}
                    noBorder
                    displayFormat="Do MMM YYYY"
                    openDirection="down"
                    hideKeyboardShortcutsPanel
                    required
                  />
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    name="partyGuestsNumber"
                    placeholder="Number of guests"
                    value={this.state.data.partyGuestsNumber}
                    onChange={this.handleChange}
                  />
                </div>
                <div className={styles.reactSelect}>
                  <ReactSelect
                    placeholder={"Supplier Budget*"}
                    styles={minimumSelectStyle}
                    className={styles.reactSelect}
                    id="supplierBudget"
                    name="supplierBudget"
                    required={true}
                    isMulti={false}
                    value={budgetOptions.filter(({ value }) => value === this.state.data.supplierBudget)}
                    onChange={this.handleChangeSelect}
                    options={budgetOptions}
                  />
                </div>
              </div>
              <div className={styles.fullwidth}>
                <textarea
                  placeholder="Notes"
                  name="enquireFormNotes"
                  value={this.state.data.enquireFormNotes}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.checkbox}>
                <input
                  id="newsletterConfirm"
                  type="checkbox"
                  name="newsletterConfirm"
                  checked={this.state.data.newsletterConfirm}
                  onChange={this.handleChange}
                />
                <label htmlFor="newsletterConfirm">
                  Keep this box checked if you would like to opt in to receiving top tips on party planning and updates
                  on our incredible suppliers
                </label>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                text={this.state.isProcessing ? "Sending..." : "Submit Enquiry"}
                type="submit"
                disabled={this.state.isProcessing}
                className={this.state.isProcessing ? styles.processing : ""}
              />
            </div>
          </form>
        </SiteWrapper>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentParty: state.party.currentParty,
    staticData: state.staticData.data,
    userAttributes: state.auth.userAttributes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    partyUpdate: (party) => dispatch(actions.partyUpdate(party)),
    partyClear: () => dispatch(actions.partyClear()),
    partyLoadFirst: () => dispatch(actions.partyLoadFirst()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnquireForm))
