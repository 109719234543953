import { motion } from "framer-motion"
import React from "react"
import * as styles from "./FetchSpinnerCard.module.scss"

const FetchSpinnerCard = ({ view = "grid", type = "card", wrapper = true, numCards = 9 }) => {
  const card = (index) => (
    <div className={styles.card} key={`${index}spinnercard`}>
      <span className={styles.image} />

      {type === "card" ? (
        <div className={styles.content}>
          <span className={styles.title} />
          <span className={styles.category} />
          <div className={styles.text}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : null}
    </div>
  )

  const cards = []
  for (let index = 0; index < numCards; index++) {
    cards.push(card(index))
  }
  return wrapper ? (
    <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} className={styles.FetchSpinnerCard}>
      <div className={styles[view]}>{cards}</div>
    </motion.div>
  ) : (
    <>{cards}</>
  )
}

export default FetchSpinnerCard
