import React, { Component } from "react"
import { connect } from "react-redux"
import * as styles from "./SupplierReviewForm.module.scss"

const mq = typeof window !== `undefined` ? window.matchMedia("(max-width: 767px)") : null

class SupplierReviewForm extends Component {
  state = {
    scored: false,
    rating: 0,
    text: "",
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.handleReviewChange(this.props.supplierId, this.state)
    }
  }

  render() {
    let stars = []

    for (let index = 1; index <= 5; index++) {
      stars.push(
        <label
          key={"stars_" + index}
          className={`${styles.star} ${index <= this.state.rating ? styles.selected : ""}`}
          onClick={() => this.setState({ rating: index, scored: true })}
        >
          ★
        </label>
      )
    }
    return (
      <div className={styles.SupplierReviewForm}>
        <form>
          <label>
            Select score<span>*</span>{" "}
            {!this.state.scored && this.state.text !== "" && (
              <div>Please select score to continue</div>
            )}
          </label>
          <fieldset className={styles.stars}>{stars}</fieldset>
          <div className={styles.written}>
            <label>Write Review</label>
            <textarea
              name="text"
              onChange={this.handleChange}
              placeholder="Add a written review"
            ></textarea>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    staticData: state.staticData.data,
  }
}

export default connect(mapStateToProps)(SupplierReviewForm)
