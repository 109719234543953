/*
 * Layout Name: Supplier Card
 * Desc:
 */
//libs
import { Auth } from "aws-amplify"
import React, { Component } from "react"
import { Image } from "react-datocms"
import ReactMarkdown from "react-markdown"
import { connect } from "react-redux"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import Link from "next/link"
import SavedSupplierNoteModal from "../../party/SavedSupplierNoteModal/SavedSupplierNoteModal"
import SupplierEnquireModal from "../SupplierEnquireModal/SupplierEnquireModal"
import SupplierReviewForm from "../SupplierReviewForm/SupplierReviewForm"
import * as styles from "./SupplierCard.module.scss"
import { eventGtag } from "@lib/gtag"
import trackFBEvent from "@lib/trackFBEvent"
import trimText from "@lib/trimText"
import lambdas from "@data/lambdas"
import * as actions from "@store/actions"
import AuthenticationModal from "@components/auth/AuthenticationModal/AuthenticationModal"
import Button from "@components/objects/Button/Button"
import bundleWhite from "@images/icons/bundleWhite.svg"
import emailDark from "@images/icons/emailDark.svg"
import homeWhite from "@images/icons/homeWhite.svg"
//assets
import discountIcon from "@images/icons/hoppIconWhite.svg"
import hoppIconWhite from "@images/icons/hoppIconWhite.svg"
import locationIcon from "@images/icons/locationMarkerDark.svg"
import note from "@images/icons/note.svg"
import saveStar from "@images/icons/saveStar.svg"
import saveStarWhite from "@images/icons/saveStarWhite.svg"
import noImage from "@images/noImage4x3.jpg"
import noImageSquare from "@images/noImageSquare.png"

const { PARTY_SUPPLIER_SUBMIT } = lambdas[process.env.NEXT_PUBLIC_ENV]

const mq = typeof window !== `undefined` ? window.matchMedia("(max-width: 767px)") : null

class SupplierCard extends Component {
  state = {
    states: ["Select Status", "Contacted", "Confirmed"],
    isSaved: this.props.isSaved,
    savedNote: this.props.savedNote,
    savedStatus: this.props.savedStatus,
    isMobile: null,
  }

  toggleMobile = () => {
    this.setState({
      isMobile: mq.matches,
    })
  }

  componentDidMount() {
    this.setState({
      isMobile: mq.matches,
    })
    mq.addListener(this.toggleMobile)
  }

  componentWillUnmount() {
    mq.removeListener(this.toggleMobile)
  }

  toggleSaveToParty = async (party) => {
    const currentParty = this.props.currentParty && this.props.currentParty.partyId ? this.props.currentParty : party

    try {
      const data = {
        createdAt: currentParty.partyId,
        supplierId: this.props.id,
        isAdding: !this.state.isSaved,
      }

      const user = await Auth.currentSession()

      const response = await fetch(PARTY_SUPPLIER_SUBMIT, {
        method: "POST",
        body: JSON.stringify({
          sessionToken: user.getIdToken().getJwtToken(),
          data: data,
        }),
      })

      if (response.ok) {
        const data = await response.json()

        const prevIsSaved = this.state.isSaved

        this.props.partySaveMessageUpdate({
          isSaving: !prevIsSaved,
          supplierImage: this.props.coverphoto ? this.props.coverphoto.responsiveImage.src : noImageSquare,
          partyName: currentParty.partyName,
        })

        //analytics
        eventGtag({
          action: !prevIsSaved ? "Saved" : "Removed",
          params: {
            event_category: "Supplier",
            event_label: this.props.name,
            value: this.props.id,
          },
        })
        eventGtag({
          action: !prevIsSaved ? "Added Supplier" : "Removed Supplier",
          params: {
            event_category: "Party",
            event_label: currentParty.partyName,
            value: currentParty.partyId,
          },
        })

        if (!prevIsSaved) trackFBEvent("AddToWishlist")

        let savedSuppliers = []

        if (!prevIsSaved) {
          const newSupplier = {
            coverphoto: this.props.coverphoto,
            description: this.props.description,
            id: this.props.id,
            name: this.props.name,
            pricerange: this.props.pricerange,
            slug: this.props.slug,
            supplierNote: "",
            supplierStatus: 0,
            supplierservices: this.props.supplierservices,
            email: this.props.email,
            stripeId: this.props.stripeId,
            stripeStatus: this.props.stripeStatus,
            stripeTrialEnd: this.props.stripeTrialEnd,
            createdby: this.props.createdby,
          }
          savedSuppliers =
            currentParty.savedSuppliers && currentParty.savedSuppliers.length
              ? [...currentParty.savedSuppliers, newSupplier]
              : [newSupplier]
        } else {
          savedSuppliers = currentParty.savedSuppliers.filter((supplier) =>
            data.result.savedSuppliers.some((savedSupplier) => savedSupplier.id == supplier.id)
          )
        }

        this.props.partyUpdate({ ...currentParty, savedSuppliers })

        this.props.partyGeneratedSuppliersChange(false)

        this.setState({
          isSaved: !prevIsSaved,
        })
      } else {
        alert(
          "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
        )
      }
    } catch (err) {
      console.log(err)
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
  }

  updateNote = async (newNote) => {
    this.setState({ savedNote: newNote })
    const savedSuppliers = this.props.currentParty.savedSuppliers.map((savedSupplier) => {
      return savedSupplier.id === this.props.id ? { ...savedSupplier, supplierNote: newNote } : savedSupplier
    })
    this.props.partyUpdate({ ...this.props.currentParty, savedSuppliers })
  }

  loopStatus = async () => {
    try {
      const newStatus = (this.state.savedStatus + 1) % this.state.states.length
      const data = {
        createdAt: this.props.currentParty.partyId,
        supplierId: this.props.id,
        status: newStatus,
        isUpdatingStatus: true,
      }

      const user = await Auth.currentSession()

      const response = await fetch(PARTY_SUPPLIER_SUBMIT, {
        method: "POST",
        body: JSON.stringify({
          sessionToken: user.getIdToken().getJwtToken(),
          data: data,
        }),
      })

      if (response.ok) {
        this.setState({ savedStatus: newStatus })
        const savedSuppliers = this.props.currentParty.savedSuppliers.map((savedSupplier) => {
          return savedSupplier.id === this.props.id ? { ...savedSupplier, supplierStatus: newStatus } : savedSupplier
        })
        this.props.partyUpdate({ ...this.props.currentParty, savedSuppliers })

        //analytics
        eventGtag({
          action: "Update",
          params: {
            event_category: "Supplier Status",
            event_label: this.props.name,
            value: this.props.id,
          },
        })
      } else {
        alert(
          "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
        )
      }
    } catch (err) {
      console.log(err)
      alert(
        "There has been an error, please refresh the page and try again. If it persists please contact us at supplier@houseofpartyplanning.com"
      )
    }
  }

  render() {
    let hoppNote = this.props.internalnote
    if (hoppNote) {
      let noteArray
      if (this.props.isFeatured) {
        noteArray = trimText(hoppNote, 100, 200, 250)
      } else {
        noteArray = trimText(hoppNote, 50, 130, 150)
      }
      hoppNote = noteArray[0]
      if (this.props.internalnote.length !== hoppNote.length) {
        const lastChar = hoppNote.charAt(hoppNote.length - 1)
        hoppNote = lastChar.match(/^[.,:!?]/) ? hoppNote.substring(0, hoppNote.length - 1) : hoppNote
        hoppNote += "..."
      }
    }

    const supplierNote = this.state.savedNote ? this.state.savedNote.substring(0, 11) + "..." : ""

    const noteButtonLabel = supplierNote || "Add Note"

    const noteButtonEmpty = {
      image: note,
      design: "outline",
      color: "grey",
      size: "small",
      text: noteButtonLabel,
    }

    const noteButtonFull = {
      design: "solid",
      color: "grey",
      size: "small",
      text: noteButtonLabel,
      className: styles.noteText,
    }

    // const price = this.props.pricerange ? ['£', '££', '£££', '££££'][this.props.pricerange - 1] : "";

    const unsavedButtonStyles = {
      image: saveStar,
      mobileImageOnly: true,
      size: "small",
      color: "white",
      text: this.state.isMobile ? "" : "Save",
      type: "button",
    }

    const saveButton = this.state.isSaved ? (
      <Button
        className={styles.showButton}
        image={saveStarWhite}
        mobileImageOnly
        size="small"
        color="pink"
        text={this.state.isMobile ? "" : "Saved"}
        onClick={this.toggleSaveToParty}
      />
    ) : (
      <Button {...unsavedButtonStyles} onClick={this.toggleSaveToParty} />
    )

    const saveOrPartyButton =
      this.props.currentParty && this.props.currentParty.partyId ? (
        saveButton
      ) : (
        <AuthenticationModal
          defaultStep="createParty"
          supplierID={this.props.id}
          toggleSaveToParty={this.toggleSaveToParty}
          button={{ ...unsavedButtonStyles }}
        />
      )

    //use first service as primary or it can be overwritten using this.props.overwriteLocation
    let primaryService =
      this.props.supplierservices && this.props.supplierservices.length ? this.props.supplierservices[0].service : ""

    const isVirtual = !!(
      this.props.suppliercategory &&
      this.props.suppliercategory.length &&
      this.props.suppliercategory.find((el) => el.virtual)
    )

    const isBundle = this.props.bundle

    primaryService = isVirtual ? "Virtual Supplier" : primaryService
    primaryService = isVirtual && isBundle ? "Virtual Bundle" : primaryService

    primaryService =
      this.props.overwriteService && this.props.overwriteService.length ? this.props.overwriteService : primaryService

    const supplierCard = (
      <div
        className={`${styles.SupplierCard} ${this.props.isFeatured ? styles.isFeatured : ""} ${
          isVirtual ? styles.isVirtual : ""
        }`}
      >
        <div className={styles.image}>
          {this.props.isFeatured && this.props.photos && this.props.photos.length ? (
            <div className={styles.photos}>
              <Slider infinite={false}>
                <Link href={"/supplier/" + this.props.slug}>
                  <a>
                    {this.props.coverphoto?.responsiveImage ? (
                      <Image
                        data={{ ...this.props.coverphoto.responsiveImage, width: 800, height: 600 }}
                        alt={this.props.category}
                      />
                    ) : (
                      <img src={noImage} alt="" />
                    )}
                  </a>
                </Link>

                {this.props.photos.map((image, i) =>
                  image.photo ? (
                    <Link key={"image " + i} href={"/supplier/" + this.props.slug}>
                      <a>
                        <Image
                          lazyLoad={false}
                          data={{ ...image.photo.responsiveImage, width: 800, height: 600 }}
                          alt=""
                        />
                      </a>
                    </Link>
                  ) : null
                )}
              </Slider>
            </div>
          ) : (
            <Link href={"/supplier/" + this.props.slug}>
              <a>
                {this.props.coverphoto?.responsiveImage ? (
                  <Image data={this.props.coverphoto.responsiveImage} alt={this.props.category} />
                ) : (
                  <img src={noImage} alt="" />
                )}
              </a>
            </Link>
          )}
        </div>

        <div className={styles.content}>
          <div className={styles.mainInfo}>
            <Link href={"/supplier/" + this.props.slug}>
              <a>
                <span className={styles.title}>{this.props.name}</span>
                <span className={styles.category}>{primaryService}</span>
                {/* <span className={styles.price}>{price}</span> */}
              </a>
            </Link>
          </div>
          {!this.props.hideIcons && (
            <div className={styles.detailInfo}>
              <Link href={"/supplier/" + this.props.slug}>
                <a>
                  <div className={styles.icons}>
                    {(this.props.addresscity || this.props.overwriteLocation) && (
                      <span className={styles.location}>
                        <img src={locationIcon} alt="Location" />
                        <span>
                          {this.props.overwriteLocation ? this.props.overwriteLocation : this.props.addresscity}
                        </span>
                      </span>
                    )}
                    {(this.props.hoppRecommended ||
                      this.props.offer ||
                      this.props.offerother ||
                      isVirtual ||
                      isBundle) && (
                      <div className={styles.badges}>
                        {((this.props.offer && this.props.offer != "0") || this.props.offerother) && (
                          <span className={styles.discount}>
                            <span className={styles.discountImg}>
                              <img src={discountIcon} alt="Reward" title="HOPP Reward" />
                            </span>
                            {this.props.offer && !isNaN(this.props.offer) && (
                              <span>{`save ${this.props.offer}% with HOPP`}</span>
                            )}
                          </span>
                        )}

                        {this.props.hoppRecommended && (
                          <span className={styles.recommended}>
                            <img src={hoppIconWhite} alt="HOPP recommended" title="HOPP recommended" />
                          </span>
                        )}

                        {isBundle && (
                          <span className={styles.bundleIcon}>
                            <img src={bundleWhite} alt="Virtual Bundle" title="Virtual Bundle" />
                          </span>
                        )}

                        {isVirtual && (
                          <span className={styles.virtualIcon}>
                            <img src={homeWhite} alt="Virtual Party" title="Virtual Party" />
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </a>
              </Link>
            </div>
          )}

          {this.props.internalnote && !this.props.hideNote && (
            <div className={styles.hoppNote}>
              <Link href={"/supplier/" + this.props.slug}>
                <a>
                  <ReactMarkdown>{hoppNote}</ReactMarkdown>
                </a>
              </Link>
            </div>
          )}

          {this.props.isEditable && (
            <div className={styles.editButtons}>
              <Button
                size="small"
                design="outline"
                color="grey"
                className={styles[this.state.states[this.state.savedStatus]]}
                text={this.state.states[this.state.savedStatus]}
                onClick={this.loopStatus}
              />
              <SavedSupplierNoteModal
                button={this.state.savedNote ? noteButtonFull : noteButtonEmpty}
                supplierNote={this.state.savedNote || ""}
                party={this.props.currentParty}
                supplierName={this.props.name}
                onClose={this.updateNote}
                supplierId={this.props.id}
              />
            </div>
          )}
        </div>
        <div className={styles.mainButtons}>
          {!this.props.isReviewable && saveOrPartyButton}

          <SupplierEnquireModal
            supplierName={this.props.name}
            supplierId={this.props.id}
            supplierService={this.props.supplierservices[0]?.id}
            supplierServiceLabel={this.props.supplierservices[0]?.service}
            supplierCreatedby={this.props.createdby}
            supplierStripeId={this.props.stripeId}
            supplierStripeStatus={this.props.stripeStatus}
            supplierStripeTrialEnd={this.props.stripeTrialEnd}
            button={{
              text: this.state.isMobile ? "" : "Contact",
              image: emailDark,
              mobileImageOnly: true,
              size: "small",
              color: "white",
              type: "button",
            }}
            data={{
              addresscity: this.props.addresscity,
              addresspostcode: this.props.addresspostcode,
              addressstreet1: this.props.addressstreet1,
              addressstreet2: this.props.addressstreet2,
              contactname: this.props.contactname,
              email: this.props.email,
              phone: this.props.phone,
              website: this.props.website,
              offer: this.props.offer,
              offerother: this.props.offerother,
            }}
            facebook={this.props.facebook}
            twitter={this.props.twitter}
            instagram={this.props.instagram}
            linkedin={this.props.linkedin}
          />
        </div>
      </div>
    )

    return this.props.isReviewable ? (
      <div className={`${this.props.isReviewable ? styles.review : ""}`}>
        {supplierCard}
        <SupplierReviewForm supplierId={this.props.id} handleReviewChange={this.props.handleReviewChange} />
      </div>
    ) : (
      supplierCard
    )
  }
}

SupplierCard.defaultProps = {
  viewStyle: "grid",
  isEditable: false,
  isRecommended: false,
}

const mapStateToProps = (state) => {
  return {
    currentParty: state.party.currentParty,
    isAuthenticated: state.auth.isAuthenticated,
    savingSupplierMessage: state.party.savingSupplierMessage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    partyUpdate: (party) => dispatch(actions.partyUpdate(party)),
    partyGeneratedSuppliersChange: (hasGeneratedSuppliers) =>
      dispatch(actions.partyGeneratedSuppliersChange(hasGeneratedSuppliers)),
    partySaveMessageUpdate: (message) => dispatch(actions.partySaveMessageUpdate(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierCard)
