import React, { useEffect, useState } from "react"
import Lottie from "react-lottie"
import Button from "@components/objects/Button/Button"
import SiteWrapper from "@components/structure/SiteWrapper/SiteWrapper"
import animationData from "./433-checked-done-pink.json"
import * as styles from "./EnquireThanks.module.scss"

const EnquireThanks = (props) => {
  const [isStopped, disableAnimation] = useState(true)
  const [isComplete, complete] = useState(false)

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => disableAnimation(false), [])

  return (
    <section className={styles.EnquireThanks}>
      <SiteWrapper>
        <div className={`${styles.animation} ${isComplete ? styles.completed : ""}`}>
          <Lottie
            options={lottieOptions}
            height={320}
            width={320}
            isStopped={isStopped}
            isPaused={isStopped}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => complete(true),
              },
            ]}
          />
        </div>
        <div className={`${styles.text} ${isComplete ? styles.completed : ""}`}>
          <h2>Thank you for your enquiry!</h2>
          <p>
            Your enquiry is on its way to {props.supplierName}. They will be in touch in the next 24
            hours at the following email address <strong>{props.userAttributes.email}</strong>
          </p>
          <Button text="Return" color="pink" onClick={props.closeModal} />
        </div>
      </SiteWrapper>
    </section>
  )
}

export default EnquireThanks
