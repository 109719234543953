/*
 * Name: Party Overview Page
 * Desc:
 *
 */
import { motion } from "framer-motion"
import InfiniteScroll from "react-infinite-scroller"
import { connect } from "react-redux"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { v4 as uuidv4 } from "uuid"
import SupplierCard from "../SupplierCard/SupplierCard"
import * as styles from "./SupplierList.module.scss"
import SupplierSavedNotice from "./SupplierSavedNotice"
import useMediaQuery from "@lib/useMediaQuery"
import FetchSpinnerCard from "@components/global/FetchSpinner/FetchSpinnerCard"
import Button from "@components/objects/Button/Button"

const SupplierList = (props) => {
  // Resize control
  const isMobile = useMediaQuery(767)

  const sliderSettings = {
    dots: true,
    infinite: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const emptyState = props.empty ? (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`${styles.empty} ${props.empty.search ? styles.searchPage : ""}`}
    >
      <div className={styles.image}>
        <img src={isMobile ? props.empty.backgroundImage.mobile : props.empty.backgroundImage.desktop} />
        <div className={styles.overlay}>
          <div className={styles.text}>
            {props.empty.title && <h2>{props.empty.title}</h2>}
            <p>{props.empty.text}</p>
            {props.empty.search ? (
              <Button text={props.empty.btnLabel} onClick={props.clearAllFilters} />
            ) : (
              <Button
                color={isMobile ? "white" : "black"}
                design="outline"
                text={props.empty.btnLabel}
                link="/suppliers-search"
              />
            )}
          </div>
        </div>
      </div>
      {props.empty.extra && (
        <div className={styles.extra}>
          <a target="_blank" href={props.empty.extra.link} rel="noreferrer">
            <p>{props.empty.extra.text}</p>
          </a>
        </div>
      )}
    </motion.div>
  ) : null

  const suppliers =
    props.suppliers && props.suppliers.length
      ? props.suppliers.map((supplier, i) => {
          if (supplier) {
            const savedData =
              props.currentParty && props.currentParty.savedSuppliers
                ? props.currentParty.savedSuppliers.find((savedSupplier) => savedSupplier.id === supplier.id)
                : false
            const isSaved = !!savedData
            const savedNote = savedData && savedData.supplierNote ? savedData.supplierNote : ""
            const savedStatus = savedData && savedData.supplierStatus ? savedData.supplierStatus : 0
            return (
              <SupplierCard
                key={supplier.id + i + uuidv4()}
                viewStyle={props.viewStyle}
                isFeatured={props.autoFeature && !isMobile && (i + 1) % 7 === 0}
                isSaved={isSaved}
                savedNote={savedNote}
                savedStatus={savedStatus}
                isEditable={props.partyPage}
                isReviewable={props.reviewPage}
                handleReviewChange={props.handleReviewChange}
                hideIcons={props.hideIcons}
                hideNote={props.hideNote}
                {...supplier}
              />
            )
          }
          return null
        })
      : null

  // slider
  const suppliersElement =
    props.isSlider && suppliers && suppliers.length ? (
      <Slider className={styles.SupplierList} {...sliderSettings}>
        {suppliers}
      </Slider>
    ) : (
      suppliers
    )

  // If there are suppliers, check slider, otherwise it's a list or grid.
  const viewStyleClass = props.viewStyle === "list" ? styles.list : styles.grid
  const layoutClass = props.isSlider ? styles.slider : viewStyleClass
  const classes = `${styles.SupplierList} ${props.className} ${props.reviewPage ? styles.review : ""} ${layoutClass}`

  // disabling infinite scroll for now
  const supplierslist =
    props.suppliers && props.suppliers.length && props.pagination ? (
      <InfiniteScroll
        useWindow
        className={classes}
        pageStart={0}
        loadMore={props.loadFunc}
        hasMore={props.hasMore}
        initialLoad={false}
        loader={props.hasMore && <FetchSpinnerCard key={"spinner"} wrapper={false} numCards={4} />}
      >
        {suppliers}
      </InfiniteScroll>
    ) : (
      <section className={classes}>{suppliersElement}</section>
    )

  const suppliersSection = (
    <motion.section initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}>
      {supplierslist}
      <SupplierSavedNotice {...props.savingSupplierMessage} />
    </motion.section>
  )

  //   while suppliers haven't been fetched show spinner if results return with 0 show emptystate otherwise load supplier section
  if (props.suppliers) {
    if (props.suppliers.length === 0) {
      return emptyState
    }
    if (props.suppliers.length) {
      return suppliersSection
    }
    return <FetchSpinnerCard view={props.viewStyle} />
  }
  return <FetchSpinnerCard view={props.viewStyle} />
}

const mapStateToProps = (state) => ({
  currentParty: state.party.currentParty,
  savingSupplierMessage: state.party.savingSupplierMessage,
})

export default connect(mapStateToProps)(SupplierList)
