import React, { useState } from "react"
import { connect } from "react-redux"
import EnquireForm from "./EnquireForm"
import EnquireThanks from "./EnquireThanks"
import * as styles from "./SupplierEnquireModal.module.scss"
import { eventGtag } from "@lib/gtag"
import trackFBEvent from "@lib/trackFBEvent"
import ReactModal from "@components/global/ReactModal/ReactModal"

const SupplierEnquireModal = (props) => {
  const [step, changeModal] = useState("form")
  const [showModal, setShowModal] = useState(false)

  //enquire modal overide for analytics
  const openModal = () => {
    setShowModal(true)
    eventGtag({
      action: "Open",
      params: {
        event_category: props.suppliers ? "Enquire All" : "Enquiry",
      },
    })
    const useremail = props.userAttributes?.email
    trackFBEvent(
      "LeadOpen",
      useremail
        ? {
            user_email: useremail,
          }
        : {}
    )
  }

  const closeModal = () => {
    setShowModal(false)
    changeModal("form")
  }

  return (
    <div>
      <ReactModal
        modalIsOpen={showModal}
        openModal={openModal}
        onRequestClose={closeModal}
        button={props.button}
        modalLabel="Enquire"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <section className={styles.SupplierEnquireModal}>
          {(() => {
            switch (step) {
              case "form":
                return (
                  <EnquireForm
                    suppliers={props.suppliers}
                    supplierId={props.supplierId}
                    supplierName={props.supplierName}
                    supplierService={props.supplierService}
                    supplierServiceLabel={props.supplierServiceLabel}
                    supplierContactInfo={props.data}
                    changeModal={changeModal}
                    closeModal={closeModal}
                    updateStatus={props.updateStatus}
                    supplierCreatedby={props.supplierCreatedby}
                    supplierStripeId={props.supplierStripeId}
                    supplierStripeStatus={props.supplierStripeStatus}
                    supplierStripeTrialEnd={props.supplierStripeTrialEnd}
                  />
                )
              case "thanks":
                return (
                  <EnquireThanks
                    supplierName={props.supplierName}
                    closeModal={closeModal}
                    userAttributes={props.userAttributes}
                  />
                )
              default:
                return null
            }
          })()}
        </section>
      </ReactModal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return { userAttributes: state.auth.userAttributes }
}

export default connect(mapStateToProps)(SupplierEnquireModal)
