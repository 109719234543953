import React, { useEffect, useState } from "react"
import * as styles from "./SupplierSavedNotice.module.scss"

const SupplierSavedNotice = ({ supplierImage, partyName, isSaving }) => {
  const [isActive, updateIsActive] = useState(false)
  const [message, updateMessage] = useState({ supplierImage, partyName, isSaving })

  // fade in message for 1500ms if new message
  useEffect(() => {
    if (
      supplierImage &&
      (supplierImage !== message.supplierImage || isSaving !== message.isSaving)
    ) {
      updateMessage({ supplierImage, partyName, isSaving })
      updateIsActive(true)
    }
    const timer = setTimeout(() => {
      updateIsActive(false)
    }, 1500)
    return () => clearTimeout(timer)
  }, [supplierImage, message.supplierImage, isSaving, message.isSaving, partyName])

  // TODO check isSaving
  const text = message.isSaving ? "Save to" : "Removed from"

  return (
    <div
      className={`${styles.SupplierSavedNotice} ${
        message.isSaving ? styles.saved : styles.removed
      } ${isActive && styles.active}`}
    >
      <img src={message.supplierImage} alt="" />
      <div className={styles.text}>
        <span>{text}</span>
        <span>{message.partyName}</span>
      </div>
    </div>
  )
}

export default SupplierSavedNotice
